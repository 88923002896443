const dataCols = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "投流消耗($)",
    prop: "input_cost",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "投流GMV($)",
    prop: "investment_flow",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "展现量",
    prop: "exhibit",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "点击量",
    prop: "click",
    align: "center",
    width: 110,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播观看量",
    prop: "watch",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播有效观看",
    prop: "valid_watch",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播商品点击数",
    prop: "goods_click",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "总支付完成数",
    prop: "pay_complete",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
];

const reportCols = [
  {
    label: "达人信息",
    prop: "name",
    align: "left",
    width: 150,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "投流消耗($)",
    prop: "input_cost",
    align: "center",
    width: 150,
    tooltip: "投流的总消耗金额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "投流GMV($)",
    prop: "investment_flow",
    align: "center",
    width: 150,
    tooltip: "投流带来的 GMV",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "ROAS",
    prop: "roas",
    align: "center",
    width: 120,
    tooltip: "投流GMV/投流消耗",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "展现量",
    prop: "exhibit",
    align: "center",
    width: 130,
    tooltip: "给达人投流的展现量",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "点击量",
    prop: "click",
    align: "center",
    width: 130,
    tooltip: "为达人投流带来的点击量",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播观看量",
    prop: "watch",
    align: "center",
    width: 160,
    tooltip: "为达人投流带来的观看量",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播有效观看",
    prop: "valid_watch",
    align: "center",
    width: 160,
    tooltip: "为达人投流带来的直播有效观看数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播商品点击数",
    prop: "goods_click",
    align: "center",
    width: 170,
    tooltip: "直播中商品的点击数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "总支付完成数",
    prop: "pay_complete",
    align: "center",
    width: 160,
    tooltip: "投流的总支付数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播商品点击率(%)",
    prop: "goods_click_tate",
    align: "center",
    width: 200,
    tooltip: "直播商品点击数 / 点击量 * 100%",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "付费转化率(%)",
    prop: "conversion_rate",
    align: "center",
    width: 170,
    tooltip: "总支付完成数 / 直播商品点击数 * 100%",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "点击成本 CPC",
    prop: "click_cost",
    align: "center",
    width: 160,
    tooltip: "投流消耗 / 点击量",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "千次曝光成本 CPM",
    prop: "exposure_cost",
    align: "center",
    width: 200,
    tooltip: "投流消耗 / 展现 * 1000",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
];
export default {
  dataCols,
  reportCols,
};
