<template>
  <div>
    <el-card header="投流分析" class="page_section_card">
      <keep-alive>
        <dataFilterCompVue
          ref="searchComp"
          :isType="false"
          @search="search"
        ></dataFilterCompVue>
      </keep-alive>
    </el-card>
    <el-card v-loading="loading">
      <div class="page_section_card display_flex">
        <el-upload
          :action="`${this.$pythonHost}investmentData/upload_investment`"
          :headers="{ token: token }"
          :before-upload="uploadBefore"
          :on-success="handleUpLoadSuccess"
          :on-error="handleUpLoadError"
          style="margin-right: 24px"
          :show-file-list="false"
        >
          <el-button type="primary">上传数据</el-button>
        </el-upload>
        <el-button type="info" @click="downTmpl()">下载模板</el-button>
      </div>
      <el-table :data="tableList" border stripe>
        <el-table-column
          v-for="item in investmentCols"
          :key="item.label"
          :label="item.label"
          :prop="Array.isArray(item.prop) ? '' : item.prop"
          :align="item.align"
          :min-width="item.width"
          :fixed="item.fixed"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <div v-if="Array.isArray(item.prop)">
              <p>{{ scope.row[item.prop[0]] }}</p>
              <p>至</p>
              <p>{{ scope.row[item.prop[1]] }}</p>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue
        @sizeChange="sizeInit"
        @currentChange="getList"
        :pageSize="postdata.page_num"
        :total="total"
        :firstPage="firstPage"
      />
    </el-card>
  </div>
</template>
<script>
import investmentCols from "@/assets/mcnTableCols/investmentCols.js";
import paginationVue from "@/components/paginationVue.vue";
import dataFilterCompVue from "../components/dataFilterComp.vue";
export default {
  components: {
    dataFilterCompVue,
    paginationVue,
  },
  data() {
    return {
      investmentCols: investmentCols.dataCols,
      loading: false,
      postdata: {
        page: 1,
        page_num: 20,
      },
      tableList: [],
      total: 0,
      firstPage: 1,
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    this.$refs.searchComp.search();
  },
  methods: {
    downTmpl() {
      window.open(
        `${
          this.$pythonHost
        }investmentData/download_investment_template?token=${localStorage.getItem(
          "token"
        )}`
      );
    },
    uploadBefore(file) {
      const isXlsx = file.name.indexOf(".xlsx") !== -1;
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isXlsx) {
        this.$message({
          message: `${file.name} 文件类型只能是 xlsx `,
          type: "error",
        });
        return false;
      }
      if (!isLt50M) {
        this.$message({
          message: `${file.name} 文件大小不能超过50M`,
          type: "error",
        });
        return false;
      }
    },
    handleUpLoadSuccess(response) {
      if (response.code === 1) {
        this.$refs.searchComp.search();
        this.$message({
          message: response.msg,
          type: "success",
        });
      } else {
        this.$alert(response.msg, "上传失败");
      }
    },
    handleUpLoadError(err) {
      this.$message({
        message: err.msg,
        type: "error",
      });
    },
    search(data) {
      this.postdata = Object.assign(this.postdata, data);
      this.postdata.page = 1;
      this.getList(1);
    },
    getList(page) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.postdata.page = page || this.firstPage;
      this.axios("get", `${this.$pythonHost}investmentData/list`, this.postdata)
        .then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.tableList = res.data.data;
            this.total = res.data.total_count;
            this.firstPage = res.data.page;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sizeInit(size) {
      this.postdata.page_num = size || 20;
      this.getList(1);
    },
  },
};
</script>
